import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './MemberItem.scss';

const MemberItem = ({ member }) => {
  return (
    <div className="member-item">
      <Link className="member-item__link" to={member.slug}>
        <div className="member-item__image">
          <img src={member.image[0]} alt={`Foto de ${member.name}`} />
        </div>
        <div className="member-item__name">{member.name}</div>
      </Link>
      {/* <article className="member-item__description">
        {member.description}
      </article> */}
    </div>
  );
};

MemberItem.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
  }),
};

export default MemberItem;
